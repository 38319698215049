const colors = {
  transparent: 'transparent',
  black: '#000',
  grey: '#929292',
  lightgrey: '#e2e2e2',
  white: '#ffffff',
  white70: 'rgba(255, 255, 255, 0.7)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white20: 'rgba(255, 255, 255, 0.2)',

  /* palette */
  greenLight: '#68cc9f',
  greenNormal: '#04aa60',
  greenDark: '#026639',
  yellowLight: '#ecdf8a',
  yellowNormal: '#e0ca3c',
  yellowDark: '#867924',
  salmonLight: '#f99d8b',
  salmonNormal: '#f55d3e',
  salmonDark: '#933725',
  spaceLight: '#343d48',
  spaceNormal: '#27293e',
  spaceDark: '#171825',
  blueLight: '#b3d4fc',
  blueNormal: '#5386e4',
  blueDark: '#315088',

  /* theme color */
  primary: '#04aa60',               // greenNormal
  primaryBright: '#01fe87',         // greenBright

  /* text colors */
  textPrimary: '#343d48',           // spaceLight
  textPrimary50: 'rgba(52, 61, 72, 0.5);',
  textSecondary: '#ffffff',         // white
  textTertiary: '#929292',          // grey

  /* background colors */
  bgPrimary: '#04aa60',             // primary
  bgSecondary: '#27293e',           // spaceNormal
  headerBg: '#27293e',              // bgSecondary
  footerBg: '#27293e',              // bgSecondary
  socketBg: '#171825',              // spaceDark
  sectionBgPrimary: '#ffffff',      // white
  sectionBgSecondary: '#f5f5f5',

  /* button colors */
  btnSolidPimaryBg: '#04aa60',      // primary
  btnSolidPrimaryText: '#ffffff',   // white
  btnSolidSecondaryBg: '#f0f0f0',
  btnSolidSecondaryHoverBg: '#dddddd',
  btnSolidSecondaryText: '#929292', // textTertiary
  btnOutlinePrimary: '#ffffff',     // white
  btnOutlineSecondary: '#343d48',   // textPrimary

  /* notification colors */
  success: '#28a745',
  warning: '#ffc107',
  error: '#dc3545',
  info: '#17a2b8',

  /* misc */
  easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)',
  primaryBoxShadow: '0px 8px 20px -6px rgba(0,0,0,0.3)',
  secondaryBoxShadow: '0px 8px 20px -6px rgba(0,0,0,1)',
}

export default colors;