/**
 *  @link https://styled-system.com/
 *  @link https://codesandbox.io/s/github/styled-system/styled-system/tree/master/examples/emotion
 *
 *  @link https://www.smashingmagazine.com/2020/04/dark-mode-react-apps-styled-components/
 *  @link https://blog.agney.dev/theming-on-styled-components/
 *  @link https://github.com/gperl27/gatsby-styled-components-dark-mode
 *  @link https://www.gatsbyjs.com/plugins/gatsby-plugin-dark-mode/
 *  @link https://chakra-ui.com/docs/features/color-mode
 */
import colors from './colors';

export const theme = {
  breakpoints: ['768px', '990px', '1170px', '1440px'],
  space: [0, 5, 8, 10, 15, 20, 25, 30, 33, 35, 40, 50, 60, 70, 80, 85, 90, 100],
  fontSizes: [10, 12, 14, 15, 17, 18, 20, 22, 24, 36, 48, 80, 96],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  fontSans: "'Inter', sans-serif",
  fontHeading: "'Poppins', sans-serif",
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  borders: [
    0,
    '1px solid',
    '2px solid',
    '3px solid',
    '4px solid',
    '5px solid',
    '6px solid',
  ],
  radius: [3, 4, 5, 10, 20, 30, 60, 120, '50%'],
  widths: [36, 40, 44, 48, 54, 70, 81, 128, 256],
  heights: [36, 40, 44, 46, 48, 54, 70, 81, 128],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors,
  colorStyles: {

    // outline buttons
    primary: {
      borderColor: colors.btnOutlinePrimary,
      backgroundColor: colors.transparent,
      color: colors.btnOutlinePrimary,
      '&:hover': {
        borderColor: colors.btnOutlinePrimary,
        backgroundColor: colors.white20,
        color: colors.btnOutlinePrimary,
        boxShadow: colors.primaryBoxShadow,
      },
    },
    secondary: {
      borderColor: colors.btnOutlineSecondary,
      backgroundColor: colors.transparent,
      color: colors.btnOutlineSecondary,
      '&:hover': {
        borderColor: colors.btnOutlineSecondary,
        backgroundColor: colors.transparent,
        color: colors.btnOutlineSecondary,
        boxShadow: colors.primaryBoxShadow,
      },
    },
    warning: {
      color: colors.warning,
      borderColor: colors.warning,
      '&:hover': {
        color: colors.warningHover,
        borderColor: colors.warningHover,
      },
    },
    error: {
      color: colors.error,
      borderColor: colors.error,
      '&:hover': {
        color: colors.errorHover,
        borderColor: colors.errorHover,
      },
    },

    // solid buttons
    primaryWithBg: {
      border: `1px solid ${colors.btnSolidPimaryBg}`,
      borderRadius: '4px',
      backgroundColor: colors.btnSolidPimaryBg,
      color: colors.btnSolidPrimaryText,
      '&:hover': {
        borderColor: colors.primaryDarker,
        backgroundColor: colors.primaryDarker,
        color: colors.btnSolidPrimaryText,
        boxShadow: colors.primaryBoxShadow,
      },
    },
    secondaryWithBg: {
      border: `1px solid ${colors.btnSolidSecondaryBg}`,
      borderRadius: '4px',
      backgroundColor: colors.btnSolidSecondaryBg,
      color: colors.btnSolidSecondaryText,
      '&:hover': {
        borderColor: colors.btnSolidSecondaryHoverBg,
        backgroundColor: colors.btnSolidSecondaryHoverBg,
        color: colors.btnSolidSecondaryText,
        boxShadow: colors.primaryBoxShadow,
      },
    },
    warningWithBg: {
      border: `1px solid ${colors.warning}`,
      borderRadius: '4px',
      backgroundColor: colors.warning,
      color: colors.white,
      '&:hover': {
        borderColor: colors.warningHover,
        backgroundColor: colors.warningHover,
      },
    },
    errorWithBg: {
      border: `1px solid ${colors.error}`,
      borderRadius: '4px',
      backgroundColor: colors.error,
      color: colors.white,
      '&:hover': {
        borderColor: colors.errorHover,
        backgroundColor: colors.errorHover,
      },
    },
    transparentBg: {
      backgroundColor: colors.transparent,
      borderColor: colors.transparent,
      '&:hover': {
        backgroundColor: colors.transparent,
        borderColor: colors.transparent,
      },
    },
  },
  buttonStyles: {
    textButton: {
      height: 'auto',
      textTransform: 'none',
      minHeight: 'inherit',
      padding: '0.4em 0',
      borderBottom: `2px solid ${colors.primary}`,
      backgroundColor: colors.transparent,
      borderRadius: '0',
      'span.btn-text': {
        padding: '0',
      }
    },
    outlined: {
      borderWidth: '1px',
      borderRadius: '4px',
      borderStyle: 'solid',
      backgroundColor: colors.transparent,
      '&:hover': {
        backgroundColor: colors.white20,
        borderColor: colors.white20,
      },
    },
    fab: {},
    extendedFab: {},
  },
};
