import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const GlobalStyle = createGlobalStyle`

  /* ==========================================================================
      Base styles
  ========================================================================== */

  ::selection {
    background: #4299e1;
    text-shadow: none;
  }

  ::-moz-selection {
    background: #4299e1;
    text-shadow: none;
  }

  hr {
    display: block;
    height: 1px;
    border-top: 1px solid #ccc;
    border: 0;
    padding: 0;
    margin: 0;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  textarea {
    resize: vertical;
  }

  section {
    position: relative;
  }

  /* ==========================================================================
      Custom styles
  ========================================================================== */

  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
  }

  html {
    color: ${themeGet('colors.textPrimary', '#343d48')};
    font-family: ${themeGet('fontSans', "'Inter', sans-serif")};
    font-size: 17px;
    line-height: 1.5;
    min-width: 320px;
  }

  body {
    background-color: ${themeGet('colors.white', '#ffffff')};
  }

  main {
    flex: 1 0 auto;
    margin-top: 100px;
    @media only screen and (max-width: 989px) {
      margin-top: 72px;
    }
  }

  main img {
    width: 100%;
  }

  footer {
    flex-shrink: 0;
  }

  section {
    padding: 5rem 0;  /* 85 */
  }

  .devtool section {
    padding: 0;
  }

  p,
  .p {
    line-height: 1.75em;
    font-size: 17px;
    margin: 0 0 2em 0;
  }

  a {
    text-decoration: none;
    color: ${themeGet('colors.textPrimary', '#343d48')};
  }

  p a,
  .styled-link {
    text-decoration: none;
    color: ${themeGet('colors.primary', '#04aa60')};
    border-bottom: 2px solid ${themeGet('colors.primary', '#04aa60')};
    padding-bottom: 0.1em;
    &:hover{
      border-bottom: none;
    }
  }

  i svg {
    margin-top: -0.15rem;
  }

  .page-layouts-wrapper {
    .title-section + * {
      padding-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .port-item-intro,
  .port-item-layouts-wrapper {
    .image-shadow {
      box-shadow: 0px 9px 30px -7px rgba(0,0,0,0.2);
    }
  }

  /* ==========================================================================
      Headings
  ========================================================================== */

  small, .small { font-size: 0.9rem; } /* 15.3px */

  h1, h2, h3, h4, h5, h6 {
    margin: 3rem 0 1.5rem 0;  /* 54px 0 27px */
    font-family: ${themeGet('fontHeading', "'Poppins', sans-serif")};
    font-weight: 700;
    letter-spacing: -0.025em;
    hyphens: none;
  }

  h1, .h1 { font-size: 4.0rem; } /* 68.0px */
  h2, .h2 { font-size: 2.7rem; } /* 45.9px */
  h3, .h3 { font-size: 2.0rem; } /* 34.0px */
  h4, .h4 { font-size: 1.4rem; } /* 23.8px */
  h5, .h5 { font-size: 1.2rem; } /* 20.4px */

  @media only screen and (max-width: 989px) {
    h1, .h1 { font-size: 3.4rem; }
    h2, .h2 { font-size: 2.2rem; }
    h3, .h3 { font-size: 1.7rem; }
    h4, .h4 { font-size: 1.3rem; }
    h5, .h5 { font-size: 1.2rem; }
  }
  @media only screen and (max-width: 767px) {
    h1, .h1 { font-size: 3rem; }
    h2, .h2 { font-size: 2.1rem; }
    h3, .h3 { font-size: 1.4rem; }
    h4, .h4 { font-size: 1.2rem; }
    h5, .h5 { font-size: 1.1rem; }
  }
  @media only screen and (max-width: 479px) {
    h1, .h1 { font-size: 2.5rem; }
    h2, .h2 { font-size: 2.1rem; }
    h3, .h3 { font-size: 1.3rem; }
    h4, .h4 { font-size: 1.15rem; }
    h5, .h5 { font-size: 1.05rem; }
    h1, h2, h3, h4, h5, h6 {
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: manual;
    }
  }

  h1:first-of-type,
  h2:first-of-type,
  h3:first-of-type,
  h4:first-of-type,
  h5:first-of-type {
    margin-top: 0;
  }

  p + div {
    margin-top: 3em;
  }

  .underlined-title {
    direction: rtl;
    letter-spacing: 0.4em;
    text-indent: -0.4em;
    font-family: ${themeGet('fontHeading', "'Poppins', sans-serif")};
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid ${themeGet('colors.primary', '#04aa60')};
    margin-bottom: 3em;
  }

  .underlined-title.large {
    letter-spacing: 0.1em;
    text-indent: -0.1em;
  }

  /* Buttons
  ========================================================================== */

  .btn {
    text-decoration: none;
    border-radius: 4px;
    padding: 1.15rem 1.666rem;
    font-weight: 600;
    font-family: ${themeGet('fontHeading', "'Poppins', sans-serif")};
    line-height: 1em;
  }

  .btn-large {
    padding: 1.4rem 2.4rem;
    font-size: 1.1rem;
  }

  .btn-small {
    padding: 0.65rem 1.2rem;
    font-size: 0.9rem;
    @media only screen and (max-width: 479px) {
      padding: 0.65rem 1rem;
    }
  }

  .btn-social {
    padding: 0;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    &.btn-icon {
      justify-content: center;
      align-items: center;
    }
  }

  .social-group a {
    margin: 0 10px 10px 0;
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 768px) {
      margin: 0 6px 6px 0;
    }
    @media screen and (min-width: 990px) {
      margin: 0 10px 10px 0;
    }
  }

  /* ==========================================================================
      Helper classes
  ========================================================================== */

  .center-align {
    text-align: center;
  }

  .left-align {
    text-align: left;
  }

  .right-align {
    text-align: right;
  }

  .text-underline {
    padding-bottom: 0.4em;
    border-bottom: 2px solid ${themeGet('colors.primary', '#04aa60')};
  }

  .text-nowrap {
    white-space: nowrap !important;
  }


  /* ==========================================================================
      Media queries
      breakpoints: ['575px', '768px', '990px', '1440px']
  ========================================================================== */

  @media only screen and (min-width: 480px) {}
  @media only screen and (min-width: 575px) {}
  @media only screen and (min-width: 768px) {}
  @media only screen and (min-width: 990px) {}
  @media only screen and (min-width: 1440px) {}

  @media only screen and (max-width: 1439px) {}
  @media only screen and (max-width: 989px) {}
  @media only screen and (max-width: 767px) {}
  @media only screen and (max-width: 574px) {}
  @media only screen and (max-width: 479px) {}

  @media only screen and (min-width: 768px) and (max-width: 1023px) {}
  @media only screen and (min-width: 768px) and (max-width: 1365px) {}
  @media only screen and (min-width: 1024px) and (max-width: 1365px) {}
  @media only screen and (min-width: 1024px) and (max-width: 1439px) {}

  @media print {}
`;
