import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import LogoImage from '../../assets/images/logo.png';
import Container from 'common/src/components/Container';
import Logo from 'common/src/components/Logo';
import Button from 'common/src/components/Button';
import Text from 'common/src/components/Text';
import UniversalLink from '../../components/UniversalLink';
import { Icon } from 'react-icons-kit';
import { navicon } from 'react-icons-kit/fa/navicon';
import { close } from 'react-icons-kit/fa/close';
import { linkedin } from 'react-icons-kit/fa/linkedin';
import { github } from 'react-icons-kit/fa/github';
import { codepen } from 'react-icons-kit/fa/codepen';

const iconSvgs = {
  "navicon": navicon,
  "close": close,
  "linkedin": linkedin,
  "github": github,
  "codepen": codepen,
};

const Navbar = () => {
  const data =  useStaticQuery(graphql`
    fragment Widget_SocialButton on WPGraphQL_Widget_Widgetbuilder_Layouts_SocialButton {
      fieldGroupName
      socialAccount
      accountLink
    }

    query GET_NAVBAR_ELEMENTS {
      wpgraphql {
        menuItems(where: {location: PRIMARY}) {
          nodes {
            id
            databaseId
            label
            url
            path
            locations
          }
        }
        widget(id: "/widgets/connect-with-me/", idType: URI) {
          widgetBuilder {
            layouts {
              ...Widget_SocialButton
            }
          }
        }
      }
    }
  `);

  const menu = data?.wpgraphql.menuItems?.nodes;
  const icons = data?.wpgraphql.widget?.widgetBuilder?.layouts;
  const year = new Date().getFullYear();

  const [state, setState] = useState({
    mobileMenu: false
  });

  const toggleHandler = () => {
    setState({
      ...state,
      mobileMenu: !state.mobileMenu
    });
  };

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false
    });
  };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          href="/"
          title="Portfolio Ruben Klein"
          aria-label="logo"
          className="main-logo"
          logoSrc={LogoImage}
          logoStyle={{width:"60", height:"42"}}
        />
        <MenuArea>
          <MenuList className="menu">
            {menu.map((item, index) => (
              <MenuItem key={`menu-link-${item.databaseId}`}>
                <UniversalLink
                  to={item.path}
                  onClick={handleRemoveMenu}
                  activeClassName="active"
                >
                  {item.label}
                </UniversalLink>
              </MenuItem>
            ))}
          </MenuList>
          <Button
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={iconSvgs['close']} />
              ) : (
                <Icon className="close" icon={iconSvgs['navicon']} />
              )
            }
            variant="textButton"
            colors="transparentBg"
            className="hamburger"
            aria-label="menu toggle"
            onClick={() => toggleHandler()}
          />
        </MenuArea>
      </Container>
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <MenuList className="menu">
          {menu.map((item, index) => (
            <MenuItem key={`mobile-menu-link-${item.databaseId}`}>
              <UniversalLink
                to={item.path}
                onClick={handleRemoveMenu}
                activeClassName="active"
              >
                {item.label}
              </UniversalLink>
            </MenuItem>
          ))}
        </MenuList>
        <CopyrightWrapper className="copyright-section">
          <IconList>
            {icons.map((icon, index) => (
              <IconItem key={`mobile-menu-profile-${index}`}>
                <UniversalLink
                  to={icon.accountLink}
                  key={`mobile-menu-icon-${index}`}
                  aria-label={`menu-${icon.socialAccount}-icon`}
                >
                  <Icon icon={iconSvgs[icon.socialAccount]} size={20} />
                </UniversalLink>
              </IconItem>
            ))}
          </IconList>
          <Text content={`© Copyright ${year}, Ruben Klein`}/>
        </CopyrightWrapper>
      </MobileMenu>
    </NavbarWrapper>
  );
};

const NavbarWrapper = styled.nav`
  width: 100%;
  padding: 25px 0;
  background-color: ${themeGet('colors.headerBg', '#27293e')};
  position: fixed;
  z-index: 9999;
  transition: ${themeGet('colors.transition')};
  @media only screen and (max-width: 989px) {
    padding: 15px 0;
  }
  .main-logo img {
    height: 50px;
    width: auto;
    transition: ${themeGet('colors.transition')};
    @media only screen and (max-width: 989px) {
      height: 42px;
    }
  }
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const MenuArea = styled.div`
  display: flex;
  align-items: center;
  .menu {
    display: flex;
    align-items: center;
    margin-right: 11px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
    @media only screen and (max-width: 1366px) {
      margin-right: 13px;
    }
    @media only screen and (max-width: 989px) {
      display: none;
    }
    li {
      margin: 0 19px;
      float: left;
      a {
        width: 100%;
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          bottom: -0.5em;
          background-color: ${themeGet('colors.white', '#ffffff')};
          transition: transform 0.25s ${themeGet('colors.easing')};
          transform-origin: right center;
          transform: scaleX(0);
        }
        &:hover::after {
          transform-origin: left center;
          transform: scaleX(1);
        }
      }
      @media only screen and (max-width: 1366px) {
        margin: 0 17px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .reusecore__button {
    &.hamburger {
      display: none;
      @media only screen and (max-width: 989px) {
        display: inline-flex;
        padding: 0;
        justify-content: flex-end;
        min-width: 35px;
        color: #fff;
        svg {
          width: 27px;
          height: auto;
        }
      }
    }
  }
`;

const MobileMenu = styled.div`
  display: none;
  top: 0;
  @media only screen and (max-width: 989px) {
    display: flex;
    width: 100%;
    height: calc(100vh - 72px);
    padding: 3em 1em;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 72px;
    flex-direction: column;
    justify-content: space-between;
    transition: transition: ${themeGet('colors.transition')};;
    background-color: ${themeGet('colors.socketBg', '#171825')};
    color: ${themeGet('colors.white', '#ffffff')};
    &.active {
      opacity: 1;
      visibility: visible;
    }
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .menu {
      padding-bottom: 20px;
      li {
        text-align: center;
        padding: 5px;
        border-top: 1px solid ${themeGet('colors.white20', 'rgba(255, 255, 255, 0.2)')};
        &:last-child {
          border-bottom: 1px solid ${themeGet('colors.white20', 'rgba(255, 255, 255, 0.2)')};
        }
        a {
          width: 100%;
          padding: 0.6rem 0;
          display: block;
          &:hover {
            color: ${themeGet('colors.greenLight', '#68cc9f')};
          }
        }
      }
    }
  }
`;

const MenuList = styled.ul``;
const MenuItem = styled.li`
  a {
    color: ${themeGet('colors.white', '#ffffff')};
    padding: 0.4rem 0;
    font-weight: 700;
    font-size: 16px;
    &.is-current,
    &.active,
    &:active {
      color: ${themeGet('colors.greenLight', '#68cc9f')};
      &::after {
        opacity: 0;
        transform: none;
      }
    }
  }
`;

const IconList = styled.ul``;
const IconItem = styled.li``;

const CopyrightWrapper = styled.div`
  text-align: center;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      margin: 0 18px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        color: ${themeGet('colors.white70', 'rgba(255, 255, 255, 0.7)')};
      }
    }
    &:hover {
      li {
        a {
          &:hover {
            color: ${themeGet('colors.greenLight', '#68cc9f')};
          }
        }
      }
    }
  }
  p {
    color: ${themeGet('colors.white50', 'rgba(255, 255, 255, 0.5)')};
    margin: 30px auto 0;
    text-align: center;
  }
`;

export default Navbar;
