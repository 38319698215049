import React from 'react';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle } from '../global.style';
import Sticky from 'react-stickynode';
import Navbar from '../Navbar';

const Header = () => {
  return (
    <>
      <ResetCSS />
      <GlobalStyle />
      <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
        <DrawerProvider>
          <Navbar />
        </DrawerProvider>
      </Sticky>
    </>
  );
};

export default Header;
