import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';

const SEO = ({ lang, title, description, image, keywords }) => {
  const meta = [];
  const link = [];
  const { pathname } = useLocation();
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl
          defaultImage: image
          author
        }
      }
    }
  `);

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    author
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`
  };

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      defaultTitle={seo.title}
      titleTemplate={`%s | ${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: seo.description
        },
        {
          name: `image`,
          content: seo.image
        },

        /* OpenGraph meta tags https://ogp.me */
        {
          property: `og:title`,
          content: seo.title
        },
        {
          property: `og:description`,
          content: seo.description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:url`,
          content: seo.url
        },
        {
          property: `og:image`,
          content: seo.image
        },

        /* Twitter meta tags */
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:creator`,
          content: author
        },
        {
          name: `twitter:title`,
          content: seo.title
        },
        {
          name: `twitter:description`,
          content: seo.description
        },
        {
          name: `twitter:image`,
          content: seo.image
        },

        /* Miscellaneous */
        {
          name: `msapplication-TileColor`,
          content: `#04aa60`,
        },

        /* Google verification tag */
        {
          name: `google-site-verification`,
          content: `BLYQNfg4_jrefoLrCSy3E8dxtQAWu-opQdIM8kTsQak`
        },
      ]
      .concat(
        keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `)
            }
          : []
      )
      .concat(meta)}
      link={[
        {
          rel: `mask-icon`,
          color: `#04aa60`,
          href: `/favicon/safari-pinned-tab.svg`,
        }
      ].concat(link)}
    />
  );
};

SEO.defaultProps = {
  meta: [],
  keywords: [],
  lang: `en`,
  title: null,
  description: null,
  image: null,
};

SEO.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default SEO;
